const collection = document.getElementsByClassName("slide");
for (let i = 0; i < collection.length; i++) {
    j = i + 1;
    collection[i].classList.add("slide" + j);
}
const circles = document.getElementsByClassName("selector");
for (let h = 0; h < circles.length; h++) {
    z = h + 1;
    circles[h].classList.add("selector" + z);
}
const svg = document.getElementsByClassName("svg");
//Removed due to loop inconsistencies causing side effects of it not working
// let count = 0;
// for (let i = 0; i < circles.length; i++) {
//
//   circles[i].addEventListener('click', _ => {
//     for (let h = 0; h < collection.length; h++) {
//       collection[h].classList.add("hidden");
//       svg[h].classList.remove("circle-color");
//       if (h === i) {
//           console.log(h , i)
//           count += 1
//           console.log(count)
//         collection[h].classList.toggle("hidden");
//         svg[i].classList.toggle("circle-color");
//       }
//     }
//   });
//
// }

// const ss1 = _ => {
//   for (let h = 0; h < collection.length; h++) {
//     z = h + 1;
//     collection[h].classList.add("hidden");
//   }
//    collection[0].classList.toggle("hidden");
// }
// const ss2 = _ => {
//   for (let h = 0; h < collection.length; h++) {
//     z = h + 1;
//     collection[h].classList.add("hidden");
//   }
//    collection[1].classList.toggle("hidden");
// }
//
const slide1 = document.querySelector("#slide-1");
const slide2 = document.querySelector("#slide-2");
const slide3 = document.querySelector("#slide-3");
const slide4 = document.querySelector("#slide-4");
const c1 = document.querySelector(".circle1");
const c2 = document.querySelector(".circle2");
const c3 = document.querySelector(".circle3");
const c4 = document.querySelector(".circle4");
const svg1 = document.querySelector(".svg1");
const svg2 = document.querySelector(".svg2");
const svg3 = document.querySelector(".svg3");
let timer = setInterval(setTimeout, 10000);

const slide2Switch = _ => {
    slide2.classList.remove("hidden");
    slide1.classList.add("hidden");
    slide3.classList.add("hidden");
    svg2.classList.add("circle-color");
    svg1.classList.remove("circle-color");
    svg3.classList.remove("circle-color");
}
const slide1Switch = _ => {
    slide1.classList.remove("hidden");
    slide2.classList.add("hidden");
    slide3.classList.add("hidden");
    svg1.classList.add("circle-color");
    svg2.classList.remove("circle-color");
    svg3.classList.remove("circle-color");
}
const slide3Switch = _ => {
    slide3.classList.remove("hidden");
    slide2.classList.add("hidden");
    slide1.classList.add("hidden");
    svg3.classList.add("circle-color");
    svg2.classList.remove("circle-color");
    svg1.classList.remove("circle-color");
}
// const slide4Switch = _ => {
//  slide2.classList.remove("hidden");
//  slide1.classList.add("hidden");
// }


if (c2 !== null) {
    c2.addEventListener('click', _ => {

        slide2Switch();
        // clearInterval(timer);
        // resetInterval();


    });
}
if (c1 !== null) {
    c1.addEventListener('click', _ => {

        slide1Switch();
        // clearInterval(timer);
        // resetInterval();

    });
}
if (c3 !== null) {
    c3.addEventListener('click', _ => {

        slide3Switch();
        // clearInterval(timer);
        // resetInterval();

    });
}


function setTimeout(){
    if(slide1.classList.contains('hidden') && slide2.classList.contains('hidden') ){
        slide1Switch();
    }else if(slide1.classList.contains('hidden') && slide3.classList.contains('hidden') ){
        slide3Switch();
    }else {
        slide2Switch();
    }
}

function resetInterval () {
    let timer = setInterval(setTimeout, 6000);
    // var seconds = (new Date()).getSeconds();
    // console.log(seconds);
}



// c4.addEventListener('click', _ => {
//   console.log('slide to the switch');
//   slide2Switch();

// });
